// eslint-disable-next-line no-unused-vars
import {useEffect} from 'react';
import logger from '../../logger';

const RegisterServiceWorker = () => {
  useEffect(() => {
    // Check that service workers are supported
    const {addEventListener} = globalThis;

    if ('serviceWorker' in navigator) {
      // Use the window load event to keep the page load performant
      addEventListener('load', () => {
        // Register a service worker hosted at the root of the
        // site using the default scope.
        navigator.serviceWorker.register('/service-worker.js').then(
          (registration) => {
            logger.log('Service worker registration succeeded:', registration);
          },
          (error) => {
            logger.log('Service worker registration failed:', error);
          }
        );
      });
    } else {
      logger.log('Service workers are not supported.');
    }
  }, []); // Passing an empty array here ensures that this hook runs once

  return null;
};

export default RegisterServiceWorker;
